<script>
import {BFormTextarea} from "bootstrap-vue";

export default {
  name: "ReasonErrorModal",
  components: {BFormTextarea},
  props:{
    ticket:{
      type:Object,
    },
    loadingSaveError:Boolean,

  },
  data:()=>{
    return {
      comment:''
    }
  },
  watch:{
    comment(newValue,oldValue){
      this.$emit('comment',newValue)
    }
  },

  methods:{
    saveError(){
      this.$emit('saveError')
    },
    openModal() {
      this.$refs.errorModal.show()
    },
  }
}
</script>

<template>
  <b-modal ref="errorModal" id="bv-modal-example" centered hide-footer>
    <template #modal-title>
      Reason
    </template>
    <div class="d-block text-center">
      Give the reason for moving back the ticket <strong>{{ ticket.ticketName }} </strong> from the column <strong> <i
      class="text-primary">{{ ticket.previousColumnName }}</i></strong> to the column <strong>{{ ticket.columnName }}</strong>
    </div>
    <div>
      <b-row class="mt-2">
        <b-col sm="2">
          <label for="textarea-large">Reason:</label>
        </b-col>
        <b-col sm="10">
          <b-form-textarea
            v-model="comment"
            id="textarea-large"
            size="lg"
            rows="8"
            placeholder="your reason....."
          ></b-form-textarea>
        </b-col>
      </b-row>
    </div>
    <b-button class="mt-3" block @click="saveError()" :disabled="loadingSaveError || comment === '' ">
      <b-spinner v-if="loadingSaveError" style="width: 24px; height: 24px"/>
      Send
    </b-button>
  </b-modal>
</template>

<style scoped>

</style>
