<script>
export default {
  name: "AlertErrorModal",
  props:{
    showErrorBox:Boolean,
    ticketValue:{
      type:Object
    }
  },
  methods:{
    openModal() {
      this.$refs.alertModal.show()
    },
  }
}
</script>

<template>
  <b-modal ref="alertModal"  centered hide-footer>
    <p style="font-size: 16px;">
      Does the ticket <strong>{{ showErrorBox ? ticketValue.ticket_name : '' }}</strong> contain an error ?
    </p>
    <hr />
    <b-row class="mt-3 d-flex align-center justify-content-center">
      <b-button class="mr-1" variant="warning"  title="Previous" @click="$refs.alertModal.hide()">
        <span>No</span>
      </b-button>

      <b-button class="mr-1" variant="primary"  title="Previous"
                @click="()=>{$emit('openReasonModal'); showErrorBox = false;$refs.alertModal.hide()}">
        <span>Yes</span>
      </b-button>
    </b-row>

  </b-modal>
</template>

<style scoped>

</style>
